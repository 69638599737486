import IRoute from "../interfaces/routes";
import HomePage from "../pages/Home/Home";
import Impressum from "../pages/Impressum/impressum";
import Datenschutz from "../pages/Datenschutz/datenschutz";
import Contact from "../pages/sections/Contact/contact";
import AboutUs from "../pages/sections/AboutUs/about";
import Leistungen from "../pages/Leistungen/leistungen";

const routes: IRoute[] = [
  {
    path: "/",
    name: "Home Page",
    component: HomePage,
    exact: true,
  },
  {
    path: "/ueberuns",
    name: "Über uns",
    component: AboutUs,
    exact: true,
  },
  {
    path: "/leistungen",
    name: "Leistungen",
    component: Leistungen,
    exact: true,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    exact: true,
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz,
    exact: true,
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: Contact,
    exact: true,
  },
];

export default routes;

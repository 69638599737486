import React from "react";
import {
  Fab,
  Box,
  Typography,
  Container,
  Grid,
  Zoom,
  useScrollTrigger,
} from "@material-ui/core";
import useStyles from "./FooterStyles";
import { Link } from "react-router-dom";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

function Copyright() {
  return <Typography variant="body1"><b>© 2022 IT SOLID GmbH</b></Typography>;
}

function ScrollTop(props: { children: any }) {
  const { children } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const scrollToTop = () => {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={scrollToTop}
        className={classes.backToTop}
        role="presentation"
      >
        {children}
      </div>
    </Zoom>
  );
}

export default function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Container maxWidth="lg">
        <Grid item container xs={12} justifyContent="flex-end">
          <ScrollTop>
            <Fab
              className={classes.scrolltop}
              size="small"
              aria-label="scroll back to top"
            >
              <KeyboardArrowUpIcon className={classes.arrow} />
            </Fab>
          </ScrollTop>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={7}
        >
          <Grid item>
            <Copyright />
          </Grid>
          <Grid item>
            <Link onClick={() => { window.scrollTo(0, 0); }} className={classes.link} {...{ to: "/impressum" }}>
              <Typography variant="body1"><b>Impressum</b></Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link onClick={() => { window.scrollTo(0, 0); }} className={classes.link} {...{ to: "/datenschutz" }}>
              <Typography variant="body1"><b>Datenschutz</b></Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link onClick={() => { window.scrollTo(0, 0); }} className={classes.link} {...{ to: "/kontakt" }}>
              <Typography variant="body1"><b>Kontakt</b></Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formular: {
        boxShadow: "10px 20px 50px 0 rgba(33, 49, 80, 0.2)",
        textAlign: "center",
        borderRadius: "5px",
    },
    container: {
        padding: "2.8em",
        paddingBottom: "3.4em",
        [theme.breakpoints.down("xs")]: {
            padding: "2em",
            paddingBottom: "3.4em",
        },
    },
    subtitle1: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        textAlign: "left",
    },
    message: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        textAlign: "left",
        fontSize: "1.3rem",
        [theme.breakpoints.down("xs")]: { fontSize: "1.2rem", paddingTop: "10px", paddingBottom: "5px" },
    },
    btn: {
        color: "white",
        fontWeight: "bold"
    },
}));

export default useStyles;

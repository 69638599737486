import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { OpenInNew } from "@mui/icons-material";
import Modal from "@mui/material/Modal";

interface YouTubeVideoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "20%",
};

// Komponente für das YouTube-Video im Modal
export default function YouTubeVideoModal({
  isOpen,
  onClose,
}: YouTubeVideoModalProps) {
  const videoUrl = "https://www.youtube.com/embed/DEIN_VIDEO_ID"; // Ersetze DEIN_VIDEO_ID durch die YouTube-Video-ID

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div style={modalStyle}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=wb7OFti4LUTZVtMs&amp;controls=0&amp;start=1&autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  );
}

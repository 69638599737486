import React from "react";
import IPage from "../../interfaces/page";
import Welcome from "../sections/Welcome/welcome";

const Home: React.FunctionComponent<IPage> = (props) => {

  return (
    <>
      <Welcome />
    </>
  );
};

export default Home;

import * as React from "react";
import { Grid } from "@material-ui/core";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";

import useStyles from "./SkillcardStyles";

interface Content {
  skillName: string;
  skillText: string;
  image: string;
}

export default function MediaCard(props: Content) {
  const classes = useStyles();

  return (
    <>
      <Grid item md={4} xs={12} className={classes.skills}>
        <Card
          sx={{
            maxWidth: 345,
            boxShadow: "10px 20px 50px 0 rgba(33, 49, 80, 0.2)",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <CardMedia
            className={classes.media}
            component="img"
            height="200"
            image={props.image}
          />
          <CardContent sx={{ minHeight: "120px", textAlign: "center", padding: "20px" }}>
            <div className={classes.skillName}>
              {props.skillName}
            </div>
            <div className={classes.skillText}>
              {props.skillText}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

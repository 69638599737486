import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Link,
  IconButton,
} from "@material-ui/core";
import useStyles from "./FooterStyles";

import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import GoogleMapReact from "google-map-react";
import YouTubeVideoModal from "../YoutubePopup/YoutubePopup";

const AnyReactComponent = ({ text }: any) => (
  <img
    style={{
      position: "relative",
      bottom: "48px",
      right: "24px",
      maxWidth: "60px",
      maxHeight: "60px",
    }}
    src="assets/location.png"
    alt=""
  />
);

const invisibleStyle = {
  opacity: 0,
  pointerEvents: "none",
};

const handleIconClick = () => {
  window.location.href =
    "https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley";
};

export default function FooterSection() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const classes = useStyles();
  return (
    <Box className={classes.box2}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item container direction="column" spacing={1} md={8}>
            <Grid item>
              <Typography className={classes.header} variant="h4">
                {"IT SOLID GmbH"}
              </Typography>
            </Grid>

            <Grid item container direction="row">
              <EmailIcon />
              <Link href="mailto:office@it-solid.de" className={classes.link2}>
                <Typography className={classes.text} variant="body1">
                  {"office@it-solid.de"}
                </Typography>
              </Link>
            </Grid>

            <Grid item container direction="row">
              <PhoneIcon />
              <Typography className={classes.text} variant="body1">
                {"+49 173 34 28 408"}
              </Typography>
            </Grid>

            <Grid item container direction="row">
              <LocationOnIcon onClick={handleIconClick} />
              <YouTubeVideoModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
              />
              <Typography className={classes.text} variant="body1">
                Am Gockert 39 | 64354 Reinheim <br /> <br />
              </Typography>
            </Grid>
          </Grid>

          <Grid item md={4}>
            <div className={classes.map}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCPE4M4Ag_N9y48Kh_kftMDbmGtGy1nOSw",
                }}
                defaultCenter={{
                  lat: 49.84652337378244,
                  lng: 8.807505206497133,
                }}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
              >
                <AnyReactComponent
                  lat={49.84652337378244}
                  lng={8.807505206497133}
                />
              </GoogleMapReact>
            </div>
          </Grid>
          <Grid item container lg={6} justifyContent="flex-end"></Grid>
        </Grid>
      </Container>
    </Box>
  );
}

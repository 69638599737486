import React from "react";
import IPage from "../../../interfaces/page";
import useStyles from "./aboutStyling";
import ueberunslogo from "./ueberunslogo.jpg";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import MediaCard from "../../../components/Card/Card";

import data from "../../../components/Card/team.json";

const AboutUs: React.FunctionComponent<IPage> = () => {
  const classes = useStyles();

  return (
    <>
      <section>
        <div className={classes.aboutSection}>
          <img className={classes.image} src={ueberunslogo} alt="ueberuns" />
          <Box className={classes.boxing}>
            <Container className={classes.titleSection}>
              <Typography variant="h1">
                Ein Team – <br /> Tausende Möglichkeiten.
              </Typography>
              <Typography variant="h2">
                Lernen Sie uns kennen. <br /> Und werden Sie ein Teil von uns.
              </Typography>
            </Container>
          </Box>
        </div>

        <div className={classes.textSection}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item md={8} xs={12} className={classes.txtWrap}>
                <Typography variant="h4">
                  <b>
                    IT SOLID ist Ihr persönlicher Experte und Ratgeber auf dem
                    Gebiet der Softwareentwicklung.
                  </b>{" "}
                  <br />
                  <br />
                </Typography>
                <Typography variant="h4">
                  Wir begleiten Sie und helfen Ihrem Unternehmen, auf dem Weg
                  zum vollen Potenzial selbstbewusst voranzuschreiten, denn
                  unser Team besteht ausschließlich aus erfahrenen Experten und
                  jungen Talenten, die Ihre Leidenschaft in der Verwirklichung
                  Ihres IT Projekts sehen.
                </Typography>
              </Grid>
              <Grid item md={4} xs={12} className={classes.imgWrap}>
                <img
                  className={classes.world}
                  src="assets/diversity.png"
                  alt="diversity"
                  width="100%"
                  height="100%"
                />
              </Grid>
            </Grid>
          </Container>
        </div>

        <div className={classes.textSectionColored}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item md={4} xs={12} className={classes.imgWrap}>
                <img
                  className={classes.world}
                  src="assets/motivation.png"
                  alt="motivation"
                />
              </Grid>
              <Grid item md={8} xs={12} className={classes.txtWrap}>
                <Typography variant="h4">
                  <b>Unsere Motivation ist der gemeinsame Weg mit Ihnen.</b>{" "}
                  <br />
                  <br />
                </Typography>
                <Typography variant="h4">
                  Unser Ziel ist es Menschen und Unternehmen durch digitale
                  Innovationen über sich hinaus wachsen zu lassen. Wir sind der
                  Überzeugung, dass ein starker Rückhalt und Freiräume für
                  eigene Ideen uns als Gemeinschaft erfolgreich machen.
                  <br />
                  <br />
                  Hierbei hilft unser Fokus auf Teamarbeit, Vertrauen und
                  Toleranz gegenüber Meinungsverschiedenheiten Ihrem
                  Unternehmen, Potenziale für die Erweiterung ihrer Fähigkeiten
                  zu erkennen und in Zukunft erfolgreich umzusetzen.
                  <br />
                  <br />
                  Gemeinsam erzielen wir Ergebnisse, die dem digitalen Wandel
                  gewachsen sind und uns als Menschen miteinander verbinden.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>

        <div className={classes.textSection}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item md={8} xs={12} className={classes.txtWrap}>
                <Typography variant="h3">
                  <b>Unsere Mitarbeiter arbeiten zu Ihrer Zufriedenheit.</b>{" "}
                  <br />
                  <br />
                </Typography>
                <Typography variant="h4">
                  Unser Team ist in den letzten Jahren gewachsen und hat an
                  vielen Innovationen zahlreicher Unternehmen mitgewirkt und ihr
                  Können unter Beweis gestellt.
                </Typography>
              </Grid>
              <Grid item md={4} xs={12} className={classes.imgWrap}>
                <img
                  className={classes.world}
                  src="assets/satisfaction.png"
                  alt="logo"
                />
              </Grid>
            </Grid>
          </Container>
        </div>

        <div className={classes.textSectionColored}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item md={4} xs={12} className={classes.imgWrap}>
                <img
                  className={classes.world}
                  src="assets/magic.png"
                  alt="magic logo"
                />
              </Grid>
              <Grid item md={8} xs={12} className={classes.txtWrap}>
                <Typography variant="h4">
                  <b>Wir bringen die Magie in Ihr Unternehmen.</b> <br />
                  <br />
                </Typography>
                <Typography variant="h4">
                  Und das schon seit über 10 Jahren.
                  <br />
                  <br />
                  Seit 2008 stehen wir deutschlandweit erfolgreich Unternehmen
                  aus den Branchen{" "}
                  <b>
                    Automotive, Airline, Aviation, Banking, Telekommunikation
                    und Behörden
                  </b>{" "}
                  zur Seite und vermitteln Einsatzkräfte für jegliche
                  Anforderungen von Projekten.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>

        <div className={classes.team}>
          <Container maxWidth="lg">
            <Typography variant="h3">
              Ihre Ansprechpartner:
              <br />
              <br />
            </Typography>
            <Grid container alignItems="center">
              {data.map((index) => (
                <Grid item md={4} xs={12} className={classes.teamMember}>
                  <MediaCard
                    firstName={index.firstName}
                    lastName={index.lastName}
                    position={index.position}
                    image={index.image}
                    tags={index.tags}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </section>
    </>
  );
};

export default AboutUs;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  media: {
    objectFit: "cover",
  },
  skills: {
    padding: "30px",
  },
  skillName: {
    fontSize: "1.2rem",
    marginBottom: "0.7em",
    marginTop: "0.5em",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: { fontSize: "1.2rem" },
  },
  skillText: {
    fontSize: "1.0rem",
    [theme.breakpoints.down("xs")]: { fontSize: "1.0rem" },
  }
}));

export default useStyles;

import React, { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { send } from 'emailjs-com';
import Card from "@mui/material/Card";


import useStyles from "./FormularStyles"

export default function FormularCard() {
    const classes = useStyles();

    const [toSend, setToSend] = useState({
        lastName: '',
        givenName: '',
        message: '',
        email: '',
        number: ''
    });

    const [isSubmitted, setIsSubmitting] = useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        send(
            'service_elblt3j',
            'template_zisq0rk',
            toSend,
            'uJMwtSDKExw8Hra3Y'
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setIsSubmitting(true);
            })
            .catch((err) => {
                console.log('FAILED...', err);
            });
    };

    const handleChange = (e: any) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    return (
        <>
            {!isSubmitted ?
                (
                    <form onSubmit={onSubmit}>
                        <Card sx={{
                            boxShadow: "10px 20px 50px 0 rgba(33, 49, 80, 0.2)",
                            borderRadius: "5px",
                            maxHeight: "50em",
                        }}>
                            <Grid item container className={classes.formular}>
                                <Grid container alignContent="center" spacing={2} className={classes.container} >
                                    <Grid item md={12}>
                                        <div className={classes.message}>Ihre Nachricht</div>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField required value={toSend.givenName} onChange={handleChange} label="Vorname" name="givenName" autoComplete="given-name" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField required value={toSend.lastName} onChange={handleChange} label="Nachname" name="lastName" autoComplete="family-name" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField required value={toSend.email} onChange={handleChange} label="E-Mail-Adresse" name="email" autoComplete="email" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField value={toSend.number} onChange={handleChange} label="Telefonnummer" name="number" autoComplete="number" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField required multiline maxRows={5} rows={5} value={toSend.message} onChange={handleChange} label="Nachricht" name="message" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid container item justifyContent="flex-end">
                                        <Grid item md={3} xs={12}>
                                            <Button fullWidth value="Submit" type='submit' variant="contained" className={classes.btn} color="primary"><b>Senden</b></Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </form>
                )
                :
                (
                    <Card sx={{
                        boxShadow: "10px 20px 50px 0 rgba(33, 49, 80, 0.2)",
                        borderRadius: "5px",
                        maxHeight: "50em",
                    }}>
                        <Grid item container className={classes.formular}>
                            <Grid container alignContent="center" spacing={2} className={classes.container} >
                                <Grid item md={1}>
                                    <img
                                        src="assets/checked.png"
                                        alt="checkbox"
                                        width="65%"
                                        height="100%"
                                    />
                                </Grid>
                                <Grid item md={11}>
                                    <Typography variant="h5" className={classes.subtitle1}>
                                        Ihre Nachricht wurde erfolgreich versendet
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                )
            }
        </>
    );
}

import React from "react";
import IPage from "../../interfaces/page";
import useStyles from "./leistungenStyling";
import dienstleistungenlogo from "./dienstleistunglogo.jpeg";

import { CardMedia, Card, Box, Container, Typography, Grid } from "@material-ui/core";

const Leistungen: React.FunctionComponent<IPage> = () => {
  const classes = useStyles();


  return (
    <>
      <section>
        <div className={classes.aboutSection}>
          <img className={classes.image} src={dienstleistungenlogo} alt="leistungen" />
          <Box className={classes.boxing}>
            <Container className={classes.titleSection}>
              <Typography variant="h1">
                Dienstleistungen – <br /> Auf Sie abgestimmt.
              </Typography>
              <Typography variant="h2">
                Profitieren Sie von unseren Beratern mit breit aufgestellten Expertisen. <br />
              </Typography>
            </Container>
          </Box>
        </div>

        <Container maxWidth="lg">
          <div className={classes.textSection}>
            <Container maxWidth="lg">
              <Typography variant="h3" align="center">
                Unsere Dienstleistungen für Ihre Projekte
                <br />
                <br />
              </Typography>

              <div className={classes.skill}>
                <Card className={classes.tag}>
                  <Grid container>
                    <Grid item md={4} xs={12} >
                      <CardMedia
                        className={classes.img}
                        component="img"
                        image="/assets/consulting.jpg"
                      />
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.imgWrap}>
                      <Typography variant="h3" className={classes.picture}>
                        Consulting <br />

                      </Typography>
                      <Typography variant="h5">
                        Unser Team bei IT SOLID ist darauf ausgelegt Sie bei Ihren individuellen Anforderungen
                        zu unterstützen und Ihnen dank unserer breit aufgestellten Technologiekompetenzen eine Auswahl an
                        zuverlässigen und effizienten Lösungen zu liefern.<br />
                        <br />
                        Hierbei bewerten wir verschiedene Ansätze und beraten Sie bei erfolgswirksamen Geschäftsentscheidungen.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </div>

              <div className={classes.skill}>
                <Card className={classes.tag}>
                  <Grid container>
                    <Grid item md={4} xs={12} >
                      <CardMedia
                        className={classes.img}
                        component="img"
                        image="/assets/entwicklung.jpg"
                      />
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.imgWrap}>
                      <Typography variant="h3" className={classes.picture}>
                        Software Entwicklung <br />
                      </Typography>
                      <Typography variant="h5">
                        Unser Team hilft Ihnen dabei spezifische Unternehmensanwendungen,
                        die diverse organisations- und kundenzentrierte Arbeitsabläufe automatisieren zu designen und entwickeln.
                        Wir entwerfen Software Lösungen von Grund auf oder optimieren bereits laufende Systeme um diese
                        reibungslos in Ihre IT-Landschaft einzubetten.<br />
                        <br />
                        Wir decken den gesamten Kreislauf für die Entwicklung von Unternehmens Software ab.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </div>

              <div className={classes.skill}>
                <Card className={classes.tag}>
                  <Grid container>
                    <Grid item md={4} xs={12} >
                      <CardMedia
                        className={classes.img}
                        component="img"
                        image="/assets/test.jpg"
                      />
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.imgWrap}>
                      <Typography variant="h3" className={classes.picture}>
                        Software Test <br />
                      </Typography>
                      <Typography variant="h5">
                        Da Softwaretests in den meisten Fällen in einem engen Zeit- und Ressourcenrahmen erfolgen,
                        sind fundierte Kenntnisse notwendig, um Softwarefehler zuverlässig und frühzeitig finden und diese zielgerichtet und
                        kosteneffizient korrigieren zu können.
                        <br />
                        <br />
                        Profitieren Sie hierbei von den umfangreichen Erfahrungen und Expertisen unseres Testteams.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </div>

              <div className={classes.skill}>
                <Card className={classes.tag}>
                  <Grid container>
                    <Grid item md={4} xs={12} >
                      <CardMedia
                        className={classes.img}
                        component="img"
                        image="/assets/automatisierung.jpg"
                      />
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.imgWrap}>
                      <Typography variant="h3" className={classes.picture}>
                        Testautomatisierung <br />
                      </Typography>
                      <Typography variant="h5">
                        Testautomatisierung hilft, den Entwicklungs- und Lebenszyklus einer Softwarelösung erheblich zu verbessern
                        und ist zur heutigen Zeit fast schon unverzichtbar.
                        <br />
                        <br />
                        Unsere qualifizierten Testautomatisierer unterstützen Sie von der Konzeption der Automatisierung
                        über den Aufbau und Vorbereitung bis hin zur finalen Automatisierung.
                        Auch unterstützen wir Sie gerne im Rahmen von laufenden Testautomatisierungsaufgaben.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </div>

              <div className={classes.skill}>
                <Card className={classes.tag}>
                  <Grid container>
                    <Grid item md={4} xs={12} >
                      <CardMedia
                        className={classes.img}
                        component="img"
                        image="/assets/rpa.jpg"
                      />
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.imgWrap}>
                      <Typography variant="h3" className={classes.picture}>
                        Robotic Process Automation <br />
                      </Typography>
                      <Typography variant="h5">
                        Mehr Zeit für strategische Arbeit und weniger Aufwand bei sich wiederholenden Aufgaben.
                        Das ist das Ziel von RPA.
                        <br />
                        <br />
                        Unser Team steht Ihnen bei der Analyse, Planung und Automatisierung von Geschäftsprozessen zur Seite und entwickelt
                        zeiteffiziente Maßnahmen zur Einsparung von Zeitressourcen in Ihrem Unternehmen.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </div>

              <div className={classes.skill}>
                <Card className={classes.tag}>
                  <Grid container>
                    <Grid item md={4} xs={12} >
                      <CardMedia
                        className={classes.img}
                        component="img"
                        image="/assets/devops.jpg"
                      />
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.imgWrap}>
                      <Typography variant="h3" className={classes.picture}>
                        DevOps <br />
                      </Typography>
                      <Typography variant="h5">
                        DevOps vereint die Synergie der Entwicklung (Development) und dem operativen Betrieb (Operations) um
                        die Anforderungen einer modernen Softwareentwicklung mit flexiblen Prozessen und einer entsprechenden Entwicklungsumgebung
                        abzubilden.
                        <br />
                        <br />
                        Verbessern und optimieren Sie ihre Entwicklung kontinuierlich indem Sie von unseren DevOps Experten profitieren.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Container>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Leistungen;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  aboutSection: {
    height: "500px",
    backgroundColor: "black",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "40vh",
    },
  },
  image: {
    position: "absolute",
    objectFit: "cover",
    opacity: "0.8",
    width: "100%",
    height: "100%",
  },
  boxing: {
    position: "relative",
    color: "#fff",
    height: "100%",
  },
  titleSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    height: "100%",
    margin: "auto",
    padding: "0 30px",
  },
  skill: {
    padding: "15px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "15px 0",
    },
  },
  skills: {
    background: theme.palette.secondary.main,
    padding: "100px",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
  },
  textSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
  },
  tag: {
    borderRadius: "5px",
    color: "#6d7b87",
    boxShadow: "10px 20px 50px 0 rgba(33, 49, 80, 0.2)",
    display: "flex",
    height: "22em",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
  img: {
    maxWidth: "-webkit-fill-available",
    height: '100%',
    width: '100%',
  },
  imgWrap: {
    padding: "2em",
  },
  picture: {
    color: theme.palette.primary.main,
  },
  textSectionColored: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "75px 0",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "25px 0",
    },
  },
  textM: {
    fontSize: "29px",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  textS: {
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  logo: {
    marginLeft: "5em",
    maxWidth: "15em",
  },
  navBtnLink: {
    borderRadius: "50px",
    background: theme.palette.primary.main,
    whiteSpace: "nowrap",
    padding: "10px 22px",
    color: "#fff",
    fontSize: "16px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    textDecoration: "none",
    textTransform: "unset",

    "&:hover": {
      transition: "all 0.2s ease-in-out",
      background: "#fff",
      color: "#010606",
    },
  },
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  nav: {
    background: "#fff",
    height: "90px",
    //marginTop: "-80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      transition: "0.8s all ease",
    },
  },
  navbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "80px",
    width: "100%",
    maxWidth: "1250px",
    zIndex: 1,
  },
  navLogo: {
    color: "#fff",
    justifySelf: "flex-start",
    cursor: "pointer",
    fontSize: "1.5rem",
    display: "flex",
    alignItems: "center",
    marginLeft: "24px",
    fontWeight: "bold",
    textDecoration: "none",
  },
  navLogoImg: {
    [theme.breakpoints.down("xs")]: {
      width: "100px",
    },
  },
  mobileIcon: {
    display: "none",

    [theme.breakpoints.down("md")]: {
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      transform: "translate(-100%, 60%)",
      fontSize: "1.8rem",
      cursor: "pointer",
      color: "#fff",
    },
  },
  // ul
  navMenu: {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    textAlign: "center",
    marginRight: "-22px",
  },
  // li
  navItem: {
    height: "80px",
  },
  // LinkS
  navLinks: {
    color: "#000",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    padding: "0 1rem",
    height: "100%",
    cursor: "pointer",

    "&:active": {
      borderBottom: "3px solid #01bf71",
    },
  },
  navBtnLink: {
    borderRadius: "50px",
    background: theme.palette.primary.main,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    padding: "10px 22px",
    color: "#fff",
    fontSize: "16px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    textDecoration: "none",
    textTransform: "unset",

    "&:hover": {
      transition: "all 0.2s ease-in-out",
      background: "#fff",
      color: "#010606",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;

import React from "react";
import IPage from "../../../interfaces/page";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import useStyles from "./contactStyles";
import data from "../../../components/Card/team.json";
import MediaCard from "../../../components/Card/Card";
import FormularCard from "../../../components/Formular/FormularCard";

const Contact: React.FunctionComponent<IPage> = () => {
  const classes = useStyles();

  return (
    <>
      <section>
        <div className={classes.aboutSection}>
          <img
            className={classes.image}
            src="/assets/Kontakt.jpeg"
            alt="Kontakt"
          />
          <Box className={classes.boxing}>
            <Container className={classes.titleSection}>
              <Typography variant="h1">
                Kontakt
              </Typography>
              <Typography variant="h2">
                Haben Sie noch Fragen oder brauchen Sie mehr Informationen?{" "}
                <br />
                Kontaktieren Sie uns einfach.
              </Typography>
            </Container>
          </Box>
        </div>

        <div className={classes.team}>
          <Container maxWidth="lg">
            <Typography variant="h1" className={classes.subtitle}>
              <b>Erzählen Sie uns von Ihrem Projekt!</b>
              <br />
            </Typography>
            <Typography variant="h4" className={classes.subtitle1}>
              Wir sind für Sie da
              <br />
              <br />
              <br />
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <MediaCard
                  firstName={data[0].firstName}
                  lastName={data[0].lastName}
                  position={data[0].position}
                  image={data[0].image}
                  tags={data[0].tags}
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <FormularCard />
              </Grid>
            </Grid>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Contact;

import React from "react";
import IPage from "../../interfaces/page";

import { Box, Container, CssBaseline, Typography } from "@material-ui/core";

const Impressum: React.FunctionComponent<IPage> = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "60vh",
          marginTop: "6em",
          marginBottom: "6em",
        }}
      >
        <CssBaseline />
        <Container maxWidth="lg">
          <Typography variant="h2">Impressum</Typography>
          <Typography variant="h5">
            <b>Impressum im Sinne des §6 MDStV & §6 TDG:</b>
          </Typography>
          <Typography variant="body1">
            IT SOLID GmbH <br />
            Am Gockert 39 <br />
            64354 Reinheim <br />
            <br />
            E-Mail: office@it-solid.de <br />
            USt-IdNr.: DE262956431 <br />
            Amtsgericht Darmstadt HRB 105258 <br /> <br />
            Geschäftsführer: Ebi Habibi <br />
            E-Mail: ebi.habibi@t-solid.de <br />
            <br />
            <Typography variant="h5">
              <b>Haftungsausschluss:</b>
            </Typography>
            Die IT SOLID GmbH übernimmt keine Gewähr für die Richtigkeit,
            Vollständigkeit und Aktualität der angebotenen Inhalte. Jegliche
            Haftung, insbesondere für Schäden, die sich aus der Nutzung des
            angebotenen Inhalts ergeben, ist ausgeschlossen. Trotz sorgfältiger
            inhaltlicher Kontrolle übernimmt die IT SOLID GmbH keine Haftung für
            die Inhalte externer Links, für die ausschließlich deren Betreiber
            verantwortlich sind. Selbstverständlich sind bei den Ausführungen
            der gesamten Website immer beide Geschlechter angesprochen, auch
            wenn aus Vereinfachungsgründen nur die männliche Form im Text
            enthalten ist. Verwendete Produktnamen sind Warenzeichen der
            jeweiligen Hersteller.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Impressum;

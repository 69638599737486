import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tag: {
    backgroundColor: theme.palette.primary.light,
    fontSize: "0.85rem",
    "padding-inline-start": "10px",
    "padding-inline-end": "10px",
    borderRadius: "12px",
    color: "#879098",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  media: {
    objectFit: "cover",
  },
  name: {
    fontSize: "1.3rem",
    marginTop: "0.6em",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: { fontSize: "1.3rem" },
  },
  title: {
    fontSize: "1.0rem",
    marginTop: "0.5em",
    [theme.breakpoints.down("xs")]: { fontSize: "1.0rem" },
  }
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  aboutSection: {
    height: "500px",
    backgroundColor: "black",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "40vh",
    },
  },
  image: {
    position: "absolute",
    objectFit: "cover",
    opacity: "0.8",
    width: "100%",
    height: "100%",
  },
  boxing: {
    position: "relative",
    color: "#fff",
    height: "100%",
  },
  title: {
    textShadow: "2px 2px #8e8f8fd9",
    fontWeight: "bold",
  },
  titleSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    height: "100%",
    margin: "auto",
    padding: "30px 30px",
  },

  subtitle: {
    color: theme.palette.primary.main,
    textAlign: "center",
    fontSize: "2rem",
    [theme.breakpoints.down("xs")]: { fontSize: "1.3rem" },
  },
  subtitle1: {
    color: "gray",
    textAlign: "center"
  },

  team: {
    padding: "100px",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
  },
}));

export default useStyles;

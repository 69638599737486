import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  welcomeSection: {
    height: "500px",
    backgroundColor: "black",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
    },
  },
  video: {
    position: "absolute",
    objectFit: "cover",
    opacity: "0.6",
    width: "100%",
    height: "100%",
  },
  boxing: {
    position: "relative",
    color: "#fff",
    height: "100%",
  },
  titleSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    height: "100%",
    margin: "auto",
    padding: "0 30px",
  },
  skills: {
    background: theme.palette.secondary.main,
    padding: "100px",
    //textAlign: "-webkit-center",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
  },
  btnWrap: {
    paddingTop: "70px",
    textAlign: "center",
  },
  btn: {
    color: "white",
    fontWeight: "bold"
  },
  textSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
  },
  imgWrap: {
    textAlign: "center",
    padding: "20px",
  },
  picture: {
    color: theme.palette.primary.light,
  },
  textSectionColored: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    padding: "75px 0",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "25px 0",
    },
  },
  textM: {
    fontSize: "29px",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  textS: {
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  logo: {
    marginLeft: "5em",
    maxWidth: "15em",
  },
  corporateLogo: {
    maxWidth: "200px",
    alignSelf: "center",
    maxHeight: "100px",
  },
  corporateLogoWrap: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
  carouselWrap: {
    padding: "50px",
  },
  founderImg: {
    maxWidth: "100%",
    alignSelf: "center",
    maxHeight: "250px",
  },
  founderImgWrap: {
    textAlign: "center",
  },
  founderQuote: {
    fontStyle: "italic",
  },
  quoteWrap: {
    alignSelf: "center",
  },
  textSlogan: {
    textAlign: "center",
  },
  navBtnLink: {
    borderRadius: "50px",
    background: theme.palette.primary.main,
    whiteSpace: "nowrap",
    padding: "10px 22px",
    color: "#fff",
    fontSize: "16px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    textDecoration: "none",
    textTransform: "unset",

    "&:hover": {
      transition: "all 0.2s ease-in-out",
      background: "#fff",
      color: "#010606",
    },
  },
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  box: {
    //backgroundColor: "#c4d8c8",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "2.5em",
  },
  box2: {
    backgroundColor: theme.palette.secondary.main,
    color: "black",
    padding: "1em",
    paddingTop: "3em",
    paddingBottom: "3em",
  },
  link: {
    color: "white",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  link2: {
    color: "black",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  map: {
    width: "400px",
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "300px",
    },
  },
  header: {
    paddingBottom: "0.6em",
  },

  text: {
    paddingLeft: "0.6em",
  },

  icon: {
    fontSize: "40px",
    marginRight: "16px",
    maxHeight: "100%",
  },
  backToTop: {
    position: "fixed",
    bottom: theme.spacing(2),
  },
  scrolltop: {
    color: theme.palette.secondary.main,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;

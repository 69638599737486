import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  aboutSection: {
    height: "500px",
    backgroundColor: "black",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "40vh",
    },
  },
  image: {
    position: "absolute",
    objectFit: "cover",
    opacity: "0.4",
    width: "100%",
    height: "100%",
  },
  boxing: {
    position: "relative",
    color: "#fff",
    height: "100%",
  },
  titleSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    height: "100%",
    margin: "auto",
    padding: "30px 30px",
  },
  textSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "70px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 0",
    },
  },
  textSectionColored: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    padding: "70px 0",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 0",
    },
  },
  logo: {
    marginLeft: "5em",
    maxWidth: "15em",
  },
  txtWrap: {
    alignSelf: "center",
  },
  imgWrap: {
    textAlign: "center",
    padding: "20px",
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "40px",
    },
  },
  world: {
    maxWidth: "250px",
    maxHeight: "250px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "150px",
      maxHeight: "150px",
    },
  },
  navBtnLink: {
    borderRadius: "50px",
    background: theme.palette.primary.main,
    whiteSpace: "nowrap",
    padding: "10px 22px",
    color: "#fff",
    fontSize: "16px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    textDecoration: "none",
    textTransform: "unset",

    "&:hover": {
      transition: "all 0.2s ease-in-out",
      background: "#fff",
      color: "#010606",
    },
  },

  team: {
    padding: "100px",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
  },
  teamMember: {
    padding: "30px",
  },
}));

export default useStyles;

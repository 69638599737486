import * as React from "react";
import { Grid } from "@material-ui/core";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";

import useStyles from "./CardStyles";

interface Content {
  firstName: string;
  lastName: string;
  position: string;
  image: string;
  tags: string[];
}

export default function MediaCard(props: Content) {
  const classes = useStyles();

  return (
    <>
      <Card
        sx={{
          boxShadow: "10px 20px 50px 0 rgba(33, 49, 80, 0.2)",
          borderRadius: "5px",
          maxHeight: "50em",
        }}
      >
        <CardMedia
          className={classes.media}
          component="img"
          height="345"
          image={props.image}
        />
        <CardContent sx={{
          padding: "20px"
        }}>
          <div className={classes.name}>
            {props.firstName} {props.lastName}
          </div>
          <div className={classes.title}>
            {props.position}
          </div>
        </CardContent>
        <CardActions sx={{ padding: "16px", paddingBottom: "32px" }}>
          <Grid container spacing={1}>
            {props.tags.map((tag) => (
              <Grid item>
                <div className={classes.tag}>
                  {tag}
                </div>
              </Grid>
            ))}
          </Grid>
        </CardActions>
      </Card>
    </>
  );
}

import * as React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  Box,
  useMediaQuery,
} from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";

import MenuIcon from "@material-ui/icons/Menu";
//import PersonIcon from "@material-ui/icons/Person";
//import BusinessIcon from "@mui/icons-material/Business";
//import ServiceIcon from "@mui/icons-material/MiscellaneousServices";

import useStyles from "./NavbarStyles";
import logo from "./IT_Solid_Logo.svg";
import { ListItemButton } from "@mui/material";

export default function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setOpen] = React.useState<Boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!isOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Toolbar className={classes.nav}>
        <div className={classes.navbarContainer}>
          <Link
            onClick={handleClose}
            className={classes.navLogo}
            {...{ to: "/" }}
          >
            <img
              className={classes.navLogoImg}
              src={logo}
              width="150px"
              alt="Logo"
            />
          </Link>
          {isMobile ? (
            <>
              <IconButton
                //color="#fff"
                className={classes.menuButton}
                edge="start"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
            </>
          ) : (
            <div className={classes.navMenu}>
              <li className={classes.navItem}>
                <Link className={classes.navLinks} {...{ to: "/ueberuns" }}>
                  <Typography variant="body1">
                    Über uns
                  </Typography>
                </Link>
              </li>
              <li className={classes.navItem}>
                <Link className={classes.navLinks} {...{ to: "/leistungen" }}>
                  <Typography variant="body1">
                    Leistungen
                  </Typography>
                </Link>
              </li>
              {/*<li className={classes.navItem}>
                <Link className={classes.navLinks} {...{ to: "/karriere" }}>
                  Karriere
                </Link>
          </li> */}
              <Button
                component={Link}
                className={classes.navBtnLink}
                to="/kontakt"
              >
                <Typography variant="body1">
                  <b>Kontakt</b>
                </Typography>
              </Button>
            </div>
          )}
        </div>
      </Toolbar>
      <Box
        sx={{
          display: isOpen ? undefined : "none",
          bgcolor: "rgba(71, 98, 130, 0.2)",
          pb: 2,
        }}
      >
        <List>
          <ListItem>
            <ListItemButton
              onClick={handleClose}
              component={Link}
              to="/ueberuns"
              sx={{ paddingLeft: "2rem" }}
            >
              {/* <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>*/}
              <Typography variant="h4"> Über uns</Typography>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={handleClose}
              component={Link}
              to="/leistungen"
              sx={{ paddingLeft: "2rem" }}
            >
              {/*  <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              */}
              <Typography variant="h4"> Leistungen</Typography>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={handleClose}
              component={Link}
              to="/kontakt"
              sx={{ paddingLeft: "2rem", fontWeight: "bold" }}
            >
              <Typography variant="h4">Kontakt</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );
}

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import FooterSection from "./components/Footer/FooterSection";
import Navbar from "./components/Navbar/Navbar";
import routes from "./config/routes";

const theme = createTheme({
  typography: {
    fontFamily: ["Quicksand", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#7daac0",
      light: "#7cacc43d",
    },
    secondary: {
      main: "#00000008",
    },
  },
});

theme.overrides = {
  MuiTypography: {
    h1: {
      fontSize: "2.5rem",
      marginBottom: "1em",
      [theme.breakpoints.down("xs")]: { fontSize: "1.6rem" },
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "1em",
      [theme.breakpoints.down("xs")]: { fontSize: "1.2rem" },
    },
    h3: {
      fontSize: "1.7rem",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: { fontSize: "1.5rem" },
    },
    h4: {
      fontSize: "1.5rem",
      [theme.breakpoints.down("xs")]: { fontSize: "1.1rem" },
    },
    h5: {
      fontSize: "1.2rem",
      marginBottom: "1em",
      marginTop: "1em",
      [theme.breakpoints.down("xs")]: { fontSize: "1.0rem" },
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight: "bold",
      marginBottom: "1em",
      marginTop: "1em",
      [theme.breakpoints.down("xs")]: { fontSize: "1rem" },
    },
    button: {
      textTransform: "none",
    },
  },
};

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <route.component name={route.name} {...route.props} />
                  }
                />
              );
            })}
          </Routes>
          <FooterSection />
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;

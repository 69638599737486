import { Container, Grid, Typography, Button } from "@material-ui/core";
import { Box } from "@mui/system";
import buildingsVideo from "./buildings.mp4";
import useStyles from "./welcomeStyling";
import { Link } from "react-router-dom";

import MediaCard from "../../../components/Skillcard/Skillcard";
import data from "../../../components/Skillcard/skills.json";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function Welcome() {
  const classes = useStyles();

  return (
    <>
      <section>
        <div className={classes.welcomeSection}>
          <video className={classes.video} autoPlay loop muted playsInline>
            <source src={buildingsVideo} type="video/mp4" />
          </video>
          <Box className={classes.boxing}>
            <Container className={classes.titleSection}>
              <Typography variant="h1">
                <br />
                Strategische Beratung – <br /> des digitalen Zeitalters
              </Typography>
              <Typography variant="h2">
                Denn eine sich ständig verändernde Welt <br /> erfordert
                Fachleute, die ebenso dynamisch sind
              </Typography>
            </Container>
          </Box>
        </div>

        <div className={classes.textSection}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4">
                  <b>IT SOLID</b> bietet als zuverlässiger sowie unabhängiger
                  Anbieter von vielschichtigen und hochwertigen
                  IT-Dienstleistungen, innovative Lösungen rund um das Thema
                  Digitalisierung und trifft hierbei den Nerv der Zeit. <br />{" "}
                  <br />
                </Typography>
                <Typography variant="h4">
                  Wir bieten Ihnen umfassende Kenntnisse der IT-Technologien
                  gepaart mit Management-Erfahrungen und entsprechenden
                  Branchenkenntnissen. Damit sind wir in der Lage neue und
                  kosteneffiziente Lösungen gemäß Ihrer Projektanforderungen zu
                  schaffen.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>

        <div className={classes.textSectionColored}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} className={classes.textSlogan}>
                <Typography variant="h3">
                  from strategy to delivery, on time every time <br /> <br />
                </Typography>
                <Typography variant="h4">
                  Bei <b>IT SOLID</b> unterstützen unsere strategischen Berater
                  Ihre Pläne mit Spitzenleistungen. <br />
                  Unser Fokus liegt hierbei auf der Gewährleistung einer
                  qualitativen und effizienten Umsetzung der Anforderungen.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>

        <div className={classes.skills}>
          <Container maxWidth="lg">
            <Typography variant="h3" align="center">
              Unsere Dienstleistungen für Ihre Projekte
              <br />
              <br />
              <br />
            </Typography>
            <Grid container>
              {data.map((index) => (
                <MediaCard
                  skillName={index.skillName}
                  skillText={index.skillText}
                  image={index.image}
                />
              ))}
            </Grid>
            <div className={classes.btnWrap}>
              <Button
                className={classes.btn}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                component={Link}
                to="/leistungen"
                size="large"
                variant="contained"
                color="primary"
              >
                Zu unseren Leistungen
              </Button>
            </div>
          </Container>
        </div>

        <div className={classes.textSectionColored}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} className={classes.founderImgWrap}>
                <img
                  className={classes.founderImg}
                  src="assets/ebiAE2.png"
                  alt="Founder"
                />
              </Grid>

              <Grid item xs={12} md={8} className={classes.quoteWrap}>
                <Typography variant="h4" className={classes.founderQuote}>
                  „Wir schaffen eine <b>Kultur</b>, die uns inspiriert
                  Unternehmen, Ideen und Menschen zusammenzubringen um
                  überzeugende Innovationen anzustoßen, diese mit uns wachsen zu
                  lassen und eine <b>glänzende Zukunft</b> aufzubauen.“
                  <br />
                </Typography>
                <Typography variant="h5">
                  Ebi Habibi, Founder IT SOLID
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Container maxWidth="lg">
          <div className={classes.carouselWrap}>
            <Typography variant="h3" align="center">
              Unsere Referenzen
              <br />
              <br />
              <br />
            </Typography>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              centerMode={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              keyBoardControl={true}
              //customTransition="all .5"
              //transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/ibm.png"
                  alt="ibm"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/db.png"
                  alt="db"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/nttdata.png"
                  alt="nttdata"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/commerzbank.png"
                  alt="commerzbank"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/vodafone.png"
                  alt="vodafone"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/hpt.png"
                  alt="hpt"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/gtue.png"
                  alt="gtue"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/tsystems.png"
                  alt="tsystems"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/ing.png"
                  alt="ing"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/destatis.svg"
                  alt="destatis"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/fi.webp"
                  alt="fi"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/dresdnerbank.png"
                  alt="dresdnerbank"
                />
              </div>
              <div className={classes.corporateLogoWrap}>
                <img
                  className={classes.corporateLogo}
                  src="assets/corporateLogos/lufthansa.svg"
                  alt="lufthansa"
                />
              </div>
            </Carousel>
          </div>
        </Container>
      </section>
    </>
  );
}
